import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import PropertyForm from "#/components/propertyForm/propertyForm";
import BackComponent from "#/components/propertycomponents/back-component";
import usePropertyForm from "#/hooks/propertyservice-hooks/usePropertyForm";
import { getPropertyById } from "#/store/actions/property.actions";

import {
  CREATED_FOR,
  OPTIONS_PROPERTY_TYPE,
  PROPERTY_ROLES
} from "#/components/propertyForm/propertyForm.constants";
import { returnUserData } from "#/apis/propertyServiceApi";
import useFetch from "#/hooks/util-hooks/useFetch";
import { CircularProgress } from "@material-ui/core";

const EditProperty = () => {
  const dispatch = useDispatch();
  const { liveizy_id } = returnUserData();
  const history = useHistory();
  const { propertyId: urlPropertyId } = useParams();
  const { data, loading } = useFetch(`/myteams`);

  const initialPropValues = useSelector((state) => state.property.propertyById);
  const { propertyFormHelpers } = usePropertyForm(true, history, data);

  const {
    propertyCoverImage,
    propertyCoverImagePreview,
    handleSetCoverImage,
    propertyName,
    handleSetPropertyName,
    addressDropdownHelpers,
    addMultipleImagesHelpers,
    errors,
    handleSubmitProperty,
    creatingProperty,
    propertyTypeHelpers,
    setPropertyCoverImage,
    setPropertyCoverImagePreview,
    setPropertyName,
    previouslyUploadedImages,
    setPreviouslyUploadedImages,
    handleRemovePreviouslyUploadedImage,
    createPropertyAs,
    createPropertyFor,
    companyCreatedFor
  } = propertyFormHelpers;

  const COMPANIES = data?.data
    ? [
        { mainValue: "select", displayValue: "Select Company" },
        ...data?.data.map((team) => ({
          mainValue: team?.business?._id,
          displayValue: team?.business?.legal_name
        }))
      ]
    : [{ mainValue: "select", displayValue: "Select Company" }];

  //If Property already exists and thus user wants to edit
  useEffect(() => {
    if (initialPropValues) {
      const {
        property_images,
        title,
        house_number,
        street,
        state,
        lga,
        lcda,
        property_type,
        other_address,
        createdFor,
        created_by,
        companyId
      } = initialPropValues;

      // Set default property image
      if (property_images?.length > 0) {
        setPropertyCoverImagePreview((prev) =>
          prev !== property_images[0] ? property_images[0] : prev
        );
        setPropertyCoverImage((prev) =>
          prev !== property_images[0] ? property_images[0] : prev
        );
      }

      // Set default property name
      if (propertyName !== title) {
        setPropertyName((prev) => (prev !== title ? title : prev));
      }

      // Set default property address
      addressDropdownHelpers.setAddress({
        houseNumber: house_number,
        line: street,
        state: state,
        lga: lga,
        lcda: lcda,
        otherAddress: other_address
      });

      // Set default property created for
      const selectedPropertyCreatedFor = CREATED_FOR.find(
        (item) => item.mainValue === createdFor
      );
      if (
        createPropertyFor.selectedOption?.mainValue !==
        selectedPropertyCreatedFor?.mainValue
      ) {
        createPropertyFor.setSelectedOption(selectedPropertyCreatedFor);
      }

      // Set default property type
      const selectedPropertyTypeObject = OPTIONS_PROPERTY_TYPE.find(
        (item) => item.mainValue === property_type
      );
      if (
        propertyTypeHelpers.selectedOption?.mainValue !==
        selectedPropertyTypeObject?.mainValue
      ) {
        propertyTypeHelpers.setSelectedOption(selectedPropertyTypeObject);
      }

      // Set default property created by
      const selectedPropertyCreatedBy = PROPERTY_ROLES.find(
        (item) => item.mainValue === created_by
      );
      if (
        createPropertyAs.selectedOption?.mainValue !==
        selectedPropertyCreatedBy?.mainValue
      ) {
        createPropertyAs.setSelectedOption(selectedPropertyCreatedBy);
      }

      // Set default property company (if COMPANIES are loaded)
      // const selectedPropertyCompany = COMPANIES?.find(
      //   (item) => item.mainValue === companyId
      // );
      // if (
      //   selectedPropertyCompany &&
      //   companyCreatedFor.selectedOption?.mainValue !== selectedPropertyCompany.mainValue
      // ) {
      //   companyCreatedFor.setSelectedOption(selectedPropertyCompany);
      // }

      // Set default other property images
      const otherImages = property_images.slice(1); // Skip the first image (cover image)
      if (JSON.stringify(previouslyUploadedImages) !== JSON.stringify(otherImages)) {
        setPreviouslyUploadedImages(otherImages);
      }
    }
  }, [
    initialPropValues,
    propertyName,
    addressDropdownHelpers,
    createPropertyFor,
    propertyTypeHelpers,
    createPropertyAs,
    COMPANIES,
    companyCreatedFor,
    previouslyUploadedImages
  ]);

  useEffect(() => {
    dispatch(getPropertyById(urlPropertyId, "charges", "tenant"));
  }, [urlPropertyId]);

  if (
    initialPropValues &&
    initialPropValues.owner !== liveizy_id &&
    initialPropValues.property_manager !== liveizy_id &&
    !initialPropValues.otherManagers.includes(liveizy_id)
  ) {
    history.push("/property/list");
    return null;
  }

  if (loading)
    return (
      <div className="tw-flex mtop-8 tw-justify-center tw-items-center">
        <CircularProgress />
      </div>
    );
  return (
    <>
      <div style={{ marginBottom: "20px", display: "inline-block" }}>
        <BackComponent />
      </div>
      <PropertyForm
        propertyId={urlPropertyId}
        handleSetCoverImage={handleSetCoverImage}
        propertyCoverImagePreview={propertyCoverImagePreview}
        propertyCoverImage={propertyCoverImage}
        handleSetPropertyName={handleSetPropertyName}
        propertyName={propertyName}
        addressDropdownHelpers={addressDropdownHelpers}
        creatingProperty={creatingProperty}
        multipleImagesHelpers={addMultipleImagesHelpers}
        errors={errors}
        propertyTypeHelpers={propertyTypeHelpers}
        isForEdit={true}
        handleSubmitProperty={handleSubmitProperty}
        previouslyUploadedImages={previouslyUploadedImages}
        handleRemovePreviouslyUploadedImage={handleRemovePreviouslyUploadedImage}
        styles={{ borderRadius: "20px" }}
        createPropertyAs={createPropertyAs}
        createPropertyFor={createPropertyFor}
        companyCreatedFor={companyCreatedFor}
      />
    </>
  );
};

export default EditProperty;
