import { propertyServiceApi } from "#/apis/propertyServiceApi";
import axios from "axios";
import { useState, useEffect, useCallback } from "react";

const useFetch = (url, options = {}, api = propertyServiceApi) => {
  const { loadingMessage, errorMessage } = options;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback(
    async (signal) => {
      if (!url) return;

      setLoading(true);
      setError(null);
      setData(null);

      let isMounted = true; // Flag to track if component is still mounted

      try {
        const response = await api().get(url, { signal });
        const result = response.data.content || response.content || response.data;
        if (isMounted && result) {
          setData(result); // Only update state if the component is still mounted
        }
      } catch (err) {
        if (isMounted && !axios.isCancel(err)) {
          setError(err || errorMessage || "An error occurred.");
        }
      } finally {
        if (isMounted) {
          setLoading(false); // Only set loading to false if the component is still mounted
        }
      }

      // Cleanup function: Set isMounted to false on component unmount
      return () => {
        isMounted = false;
      };
    },
    [url, errorMessage, api]
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchData(controller.signal);

    // Cleanup function: abort request and prevent state updates after unmount
    return () => {
      controller.abort();
    };
  }, [url, fetchData]);

  const refetch = () => {
    fetchData();
  };

  return { data, loading, error, refetch, loadingMessage, errorMessage };
};

export default useFetch;
