import React, { useEffect, useState } from "react";
import { CircularProgress, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ApartmentDetails from "./components/ApartmentDetails/ApartmentDetails";
import MonthlyRentBlock from "../components/monthlyrent/MonthlyRentBlock";
import OtherPaymentsSection from "./components/Payments/OtherPaymentsSection";
import useFetch from "#/hooks/util-hooks/useFetch";
import MonthlyRentStatusActive from "../components/monthlyrent/MonthlyRentStatusActive";
import ShowCalculator from "#/pages/Dashboard/components/dashboard-showcase/components/monthlyRateModal/ShowCalculator";
import { getMonthlyRentDetails } from "#/store/actions/tenant.actions";

const TenantDashboard = ({
  renew,
  setRenew,
  view,
  apartmentID,
  liveizyID,
  renewal,
  renewLoading,
  refetch,
  tenantRecord,
  retry
}) => {
  const dispatch = useDispatch();

  const [statusAction, setStatusAction] = useState(false);
  // const { tenantRecord } = useSelector((store) => store.tenant);

  const monthlyRentDetail = useSelector((store) => store.tenant.monthlyRent);

  const { data, loading, error } = useFetch(
    apartmentID ? `/invoice/get_apartment_invoice/${apartmentID}` : null
  );

  const { data: preference } = useFetch(
    liveizyID ? `/bill-preference/${liveizyID}` : null
  );

  const {
    data: apartmentBills,
    loading: apartmentLoading,
    refetch: refetchApartment
  } = useFetch(apartmentID ? `/bill/get_apartment_bills/${apartmentID}` : null);

  const lastInvoice = data?.data?.[data?.data?.length - 1];

  const monthlyRentStatus = () => {
    if (statusAction) {
      return <MonthlyRentStatusActive data={data} statusAction={true} />;
    }
    return null;
  };

  useEffect(() => {
    if (liveizyID) {
      dispatch(getMonthlyRentDetails(liveizyID));
    }
  }, [liveizyID, dispatch]);

  if (loading && renewLoading) {
    return (
      <div className="tw-flex tw-justify-center tw-mt-20">
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return <div>Error loading invoices: {error}</div>;
  }
  return (
    <Container>
      <ShowCalculator isTenant={true} />
      <div
        className="tw-bg-white xs:tw-px-10 tw-py-12 tw-px-5 tw-rounded-2xl"
        style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
        <ApartmentDetails
          view={view}
          record={tenantRecord[0]}
          details={monthlyRentDetail[0]}
          setStatusAction={setStatusAction}
          monthlyRentDetails={monthlyRentDetail}
          apartmentBills={apartmentBills}
          renew={renew}
          setRenew={setRenew}
          renewal={renewal}
          refetch={refetch}
          retry={retry}
        />
        <MonthlyRentBlock
          view={view}
          statusAction={statusAction}
          details={monthlyRentDetail[0]}
          monthlyRentDetails={monthlyRentDetail}
          data={data}
          apartmentID={apartmentID}
        />
      </div>
      {data?.data?.length > 0 &&
        lastInvoice?.payment_status === "processing" &&
        monthlyRentStatus()}
      <OtherPaymentsSection
        liveizyID={liveizyID}
        apartmentId={apartmentID}
        preference={preference}
        apartmentBills={apartmentBills}
        apartmentLoading={apartmentLoading}
        refetchApartment={refetchApartment}
      />
    </Container>
  );
};

export default TenantDashboard;

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 1.35rem;
`;
