import { makeStyles } from "@material-ui/core/styles";

export const propertyTeamStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px"
    }
  },

  search_container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "1rem"
  },

  btn: {
    color: "#F4F5F7",
    border: "none",
    textTransform: "capitalize",
    fontSize: ".8rem",
    borderRadius: "5px",
    padding: "15px 30px",
    fontFamily: "Open Sans-Semibold, sans-serif",
    fontWeight: 600,
    background: "#4786FF",

    [theme.breakpoints.down("md")]: {
      padding: "15px 10px"
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
      padding: "15px"
    },

    "& .icon": {
      fontSize: "1rem",
      marginRight: "7px",
      color: "#F4F5F7",
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
        marginRight: "3px"
      }
    },

    "&:hover": {
      color: "#F4F5F7",
      background: "#4786FF"
    },

    "&:focus": {
      color: "#F4F5F7"
    }
  },

  left_section: {
    display: "flex",
    width: "550px",
    justifyContent: "space-between",

    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },

    "& .search__result": {
      color: "#043B5C",
      fontFamily: "Open Sans-Semibold, sans-serif",
      // marginTop: "20px",
      fontSize: "13px",
      transform: "translateY(50%)",
      [theme.breakpoints.down("1112")]: {
        display: "none"
      }
    }
  },

  right_section: {
    // transform: "translateY(20px)",
  },

  section_container: {
    marginTop: "20px"
  },

  action__button: {
    display: "flex",

    [theme.breakpoints.down("sm")]: {
      display: "block"
    },

    "& .action__button__checkboxs": {
      border: "1px solid #043B5C",
      width: "120px",
      padding: "10px",
      /* margin: 0 auto; */
      textAlign: "center",
      borderRadius: "5px",

      "& label": {
        color: "#043B5C",
        fontFamily: "Open Sans, sans-serif",
        marginLeft: "12px",
        fontSize: "0.9rem",
        marginBottom: "0px",
        fontWeight: 600
      }
    },

    "& .action__button__dropdown": {
      // marginLeft: "20px"
    }
  },

  listing: {
    [theme.breakpoints.down("sm")]: {
      padding: "0px !important"
    }
  },
  grid_header: {
    display: "flex",
    width: "90%",
    margin: "0 auto"
  },
  member_card: {
    width: "100%",
    padding: "10px 10px",
    backgroundColor: "dad7cd"
  },
  list: {
    backgroundColor: "#fafafa",
    padding: "10px",
    borderRadius: "5px"
  },
  listItem: {
    marginBottom: "10px", // Add margin bottom to create space between list items
    padding: "10px", // Add padding to the list item
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#fff",
    backgroundColor: "#4786ff", // Darker background color for list items
    borderRadius: "5px" // Add border radius to list items
  },
  buttonContainer: {
    display: "flex",
    flexWrap: "wrap"
  },
  button: {
    marginLeft: "10px" // Add margin between buttons
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "5px"
  },
  formField: {
    marginBottom: theme.spacing(2)
  },
  buttonGap: {
    gap: "10px"
  }
}));
