import { makeStyles } from "@material-ui/core/styles";

export const useApartmentFormStyles = makeStyles((theme) => ({
  //-----------------------------
  //Component wrapper
  //-----------------------------
  formFields: {
    display: "grid",
    gridGap: "10%",
    gridTemplateColumns: "30% 60%",
    padding: "80px 40px",
    backgroundColor: "#fff",
    maxWidth: "1440px",
    margin: "auto",

    "& label": {
      color: "#043B5C",
      fontFamily: "Open Sans-Semibold"
    },

    //Breaking at this point because the uniqueness of the UI
    "@media(max-width: 1130px)": {
      display: "block",
      marginBottom: "0px",
      padding: "0px"
    },

    "& .first-section": {
      display: "flex",
      flexDirection: "column",
      minWidth: "260px",

      "@media(max-width: 1130px)": {
        marginBottom: "50px"
      },

      "&__title": {
        textTransform: "uppercase",
        marginBottom: "20px",
        fontSize: "1.3rem"
      },

      "&__contact-wrapper": {
        display: "flex",
        flexDirection: "column"
      }
    },

    "& .second-section": {
      display: "flex",
      flexDirection: "column",

      "@media(max-width: 1130px)": {
        backgroundColor: "#fff",
        borderRadius: "0px 0px 10px 10px",
        marginBottom: "30px"
      },

      "& .btn-add": {
        backgroundColor: "transparent",
        border: "none",
        color: "#4786FF",
        cursor: "pointer",
        alignSelf: "flex-start",
        marginTop: "20px"
      }
    }
  },
  //-----------------------------

  //-----------------------------
  // Cover image section
  //-----------------------------
  imageContainer: {
    backgroundColor: "#6EB5DF72",
    height: "250px",
    position: "relative",
    borderRadius: "10px 10px 0px 0px",
    overflow: "hidden",

    "@media(max-width: 1130px)": {
      borderRadius: "0px"
    },

    "& .image": {
      width: "100%",
      height: "100%",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat"
    },

    "& input[type=file]": {
      position: "absolute",
      opacity: 0,
      width: "100%",
      height: "100%",
      zIndex: 2,
      cursor: "pointer"
    },

    "& p": {
      textAlign: "center",
      position: "absolute",
      width: "100%",
      bottom: 0,
      padding: "15px",
      fontSize: "16px",
      cursor: "pointer",
      backgroundColor: "#DEEDFF",
      color: "#043B5C",
      fontWeight: "500",

      "@media(max-width: 1130px)": {
        backgroundColor: "#fff"
      }
    }
  },
  //-----------------------------
  //-----------------------------
  //-----------------------------

  //-----------------------------
  // Property Address section
  //-----------------------------
  propertyAddressContainer: {
    backgroundColor: "#F4F5F7",
    padding: "30px",
    borderRadius: "0px 0px 5px 5px",
    display: "flex",
    flexWrap: "wrap",
    gap: "1rem",

    "& .section": {
      marginBottom: "10px",
      width: "46%",

      "& h3": {
        marginBottom: "10px",
        fontSize: "14px",
        fontWeight: "900"
      },

      "& P": {
        fontSize: "12px",
        color: "#043B5C"
      }
    }
  },
  //-----------------------------
  //-----------------------------
  //-----------------------------

  //-----------------------------
  //Apartment wrapper
  //-----------------------------
  apartment: {
    marginBottom: "30px",

    "& .apartment-name-details": {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      gridGap: "20px",

      "@media(max-width: 1130px)": {
        display: "block"
      },

      "&__dropdown-wrapper": {
        gridColumn: "1/span 2"
      },
      "&__input-wrapper": {}
    },

    "& .rent-floor-entrance": {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      gridGap: "20px",

      "@media(max-width: 1130px)": {
        display: "block"
      },

      "&__input-wrapper": {}
    },

    "& .rent-payment-date": {
      "&__input-wrapper": {
        "& input": {
          color: "#868686"
        }
      }
    },

    "& .other-description": {
      marginBottom: "18px",

      "& h4": {
        marginBottom: "10px",
        fontSize: "14px",
        fontWeight: "700",
        color: "#043B5C",
        fontFamily: "Open Sans-Semibold"
      },

      "& textarea": {
        borderRadius: "5px",
        border: "1px solid #043B5C69",
        padding: "10px 15px",
        height: "70px",
        overflow: "auto",
        fontSize: "14px",
        width: "100%",
        resize: "none"
      }
    },

    "& .apartment-images": {
      marginBottom: "30px"
    },

    "& .charge-cards-wrapper-title": {
      marginBottom: "10px",
      fontSize: "14px",
      fontWeight: "700",
      color: "#043B5C",
      fontFamily: "Open Sans-Semibold"
    },

    "& .other-charges": {
      "& .create-charge": {
        fontSize: "13px",
        color: "#4786FF",
        cursor: "pointer",
        fontWeight: "600",
        display: "inline-block",
        marginBottom: "0px",

        "&:hover": {
          opacity: "0.5"
        }
      },

      "& .charge-fields": {
        display: "flex",
        alignItems: "flex-end",
        marginTop: "10px",

        "@media(max-width: 1130px)": {
          display: "block",
          marginBottom: "20px"
        },

        "&__wrapper": {
          marginRight: "20px",

          "@media(max-width: 1130px)": {
            marginRight: "0px",
            marginBottom: "20px"
          },

          //Custom input override

          "& .makeStyles-inputField-17": {
            marginBottom: "0px"
          },

          "& .makeStyles-inputField-19": {
            marginBottom: "0px"
          },

          "& input": {
            padding: "10px 15px"
          },

          "&:last-child": {
            marginRight: "0px"
          },

          "& .call-to-action": {
            display: "flex",

            "& .add": {
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              backgroundColor: "#E8F0F9",
              position: "relative",
              marginRight: "10px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",

              "&__icon": {
                color: "#043B5C",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)"
              }

              // "&:before": {
              //   content: '""',
              //   width: "2px",
              //   height: "10px",
              //   backgroundColor: "#043B5C",
              //   position: "absolute",
              //   top: "50%",
              //   left: "50%",
              //   transform: "translate(-50%, -50%)",
              // },

              // "&:after": {
              //   content: '""',
              //   width: "10px",
              //   height: "2px",
              //   backgroundColor: "#043B5C",
              //   position: "absolute",
              //   top: "50%",
              //   left: "50%",
              //   transform: "translate(-50%, -50%)",
              // },
            },

            "& .cancel": {
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              backgroundColor: "#F9E8E8",
              position: "relative",
              // transform: "rotate(45deg)",
              cursor: "pointer",

              "&__icon": {
                color: "#5C040A",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)"
              }

              // "&:before": {
              //   content: '""',
              //   width: "2px",
              //   height: "10px",
              //   backgroundColor: "#5C040A",
              //   position: "absolute",
              //   top: "50%",
              //   left: "50%",
              //   transform: "translate(-50%, -50%)",
              // },

              // "&:after": {
              //   content: '""',
              //   width: "10px",
              //   height: "2px",
              //   backgroundColor: "#5C040A",
              //   position: "absolute",
              //   top: "50%",
              //   left: "50%",
              //   transform: "translate(-50%, -50%)",
              // },
            }
          }
        }
      }
    },

    "& .advertise-apartment": {
      display: "flex",
      alignItems: "center",
      marginTop: "30px",
      marginBottom: "30px",
      marginTop: "50px",

      "& input": {
        marginRight: "10px"
      },

      "& label": {
        fontSize: "14px",
        fontWeight: "700",
        marginBottom: "0px"
      }
    }
  },

  //-----------------------------
  //-----------------------------
  //-----------------------------

  //-----------------------------
  // Vacant
  //-----------------------------
  schedules: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "20px",

    "@media(max-width: 1130px)": {
      display: "block",
      marginBottom: "30px"
    },

    "& .schedule-inputs": {},

    "& .schedule-box": {
      border: "1px solid #043B5C69",
      position: "relative",
      overflow: "auto",
      minHeight: "200px",
      overflowY: "auto",
      marginTop: "30px",
      borderRadius: "5px",

      "& h4": {
        position: "sticky",
        top: 0,
        padding: "10px 15px",
        backgroundColor: "#6EB5DF72",
        marginBottom: "0px",
        height: "46px",
        zIndex: "2",
        display: "flex",
        alignItems: "center",
        fontWeight: "600",
        fontSize: "16px"
      },

      "& .schedule": {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        padding: "7px 15px",
        cursor: "pointer",

        "&:hover": {
          backgroundColor: "#F3F3F3"
        },

        "& .day": {
          textTransform: "capitalize",
          width: "80px"
        }
      }
    }
  },

  scheduleTimeWrapper: {
    marginBottom: "30px",

    "& label": {
      display: "inline-block",
      marginBottom: "10px",
      fontSize: "14px",
      fontWeight: "700"
    },

    "& .time-wrapper": {
      display: "flex",
      alignItems: "center",

      "& .MuiTextField-root": {
        border: "1px solid #043B5C69",
        borderRadius: "5px",
        padding: "5px 10px",

        "& .MuiInputBase-input": {
          padding: "2px 0 2px",
          color: "rgba(0,0,0,0.5)"
        },

        "& .MuiInput-underline:before": {
          display: "none"
        },

        "& .MuiInput-underline:after": {
          display: "none"
        }
      },

      "& span": {
        margin: " 0 15px"
      }
    }
  },
  btnRed: {
    backgroundColor: "#CF0713AD",
    color: "#fff",
    borderRadius: "5px",
    border: "1px solid #CF0713AD",
    padding: "11px 25px",
    fontSize: "11px",
    cursor: "pointer"
  },

  //-----------------------------
  //-----------------------------
  //-----------------------------

  //-----------------------------
  // Occupied
  //-----------------------------
  addedTenant: {
    marginBottom: "15px",

    "& .title": {
      fontSize: "14px",
      marginBottom: "0px",
      fontWeight: "600",
      color: "#043B5C",
      marginBottom: "10px"
    },

    "& .details": {
      backgroundColor: "#F3F3F3",
      borderRadius: "5px",
      padding: "15px 15px",
      cursor: "pointer",
      display: "inline-block",

      "& h3": {
        fontSize: "14px",
        marginBottom: "5px",
        fontWeight: "600",
        color: "#043B5C"
      },

      "& h4": {
        fontSize: "14px",
        marginBottom: "0px",
        color: "#043B5C"
      }
    }
  },

  //-----------------------------
  //-----------------------------
  //-----------------------------
  //-----------------------------
  // Input Field
  //-----------------------------
  inputField: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: "30px",

    "& label": {
      marginBottom: "10px",
      fontSize: "14px",
      fontWeight: "700"
    },

    "& h6": {
      color: "#043B5C7F",
      fontSize: "10px",
      marginBottom: "10px"
    },

    "& input": {
      borderRadius: "5px",
      border: "1px solid #043B5C69",
      padding: "10px 25px",
      width: "100%"
    }
  },
  errorMessage: {
    color: "red",
    fontSize: "14px",
    marginTop: "5px"
  },
  //-----------------------------
  //-----------------------------
  //-----------------------------

  //-----------------------------
  // Submit form button
  //-----------------------------
  buttonContainer: {
    gridColumn: "1 /span 2",
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    alignItems: "center",

    "@media(max-width: 1130px)": {
      height: "100px"
    },

    "& button": {
      "@media(max-width: 1130px)": {
        width: "100%"
      }
    }
  },

  btnBlue: {
    backgroundColor: "#4786FF",
    color: "#fff",
    borderRadius: "5px",
    border: "1px solid #4786FF",
    padding: "11px 35px",
    fontSize: "11px",
    cursor: "pointer"
  },

  //-----------------------------
  //-----------------------------
  //-----------------------------

  addAnotherApartment: {
    fontSize: "13px",
    color: "#4786FF",
    fontWeight: "700",
    cursor: "pointer",
    display: "inline-block",

    "&:hover": {
      opacity: "0.5"
    }
  },

  apartmentCharges: {
    marginBottom: "30px"
  },

  chargeCardsWrapper: {
    display: "flex",
    flexWrap: "wrap"
  },

  chargeCard: {
    display: "flex",
    backgroundColor: "#F3F3F3",
    borderRadius: "5px",
    padding: "15px 15px",
    cursor: "pointer",
    transition: "all 200ms ease-in-out",
    marginRight: "15px",
    marginBottom: "15px",

    "&:hover": {
      backgroundColor: "#6EB5DF72",
      transform: "scale(1.05)"
    },

    "& .left-column": {
      marginRight: "15px",

      "& h3": {
        fontSize: "16px",
        marginBottom: "10px",
        fontWeight: "600",
        color: "#043B5C"
      },

      "& h4": {
        fontSize: "14px",
        marginBottom: "0px",
        color: "#043B5C"
      }
    },

    "& .charge-amount": {
      fontSize: "16px",
      color: "#043B5C"
    }
  }
}));
